import React, { useContext, useState } from "react"

import PropTypes from "prop-types"

import dropOffDark from "../../assets/images/Icons/dropoff-dark.svg"
import dropOff from "../../assets/images/Icons/dropoff.svg"
import identityDark from "../../assets/images/Icons/IdentityDark.svg"
import identityLight from "../../assets/images/Icons/IdentityLight.svg"
import pickUpDark from "../../assets/images/Icons/pick-up-dark.svg"
import pickUpLight from "../../assets/images/Icons/pick-up.svg"
import starLight from "../../assets/images/Icons/Star-light.svg"
import starDark from "../../assets/images/Icons/Star.svg"
import { AuthenticationContext } from "../../context/"
import Link from "../../utils/link"
import AppNavbar from "./AppNavbar"
import HomeInfo from "./HomeInfo"

import "./HomescreenComponent.scss"

const Homescreen = () => {
  const { logOut, user, isLoadingRequest } = useContext(AuthenticationContext)
  const [mouseHover, setMouseHover] = useState("")
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)

  const WashButton = ({ text, icon, svgIcon, isSelected }) => {
    let className = "homescreen-component__body__buttongroup__button"
    if (isSelected)
      className += " homescreen-component__body__buttongroup__button--selected"
    return (
      <div className={className}>
        <div className="button">
          <div className="button__top">
            <img src={svgIcon}></img>
          </div>
          <div className="button__bottom">
            <div>
              <img src={icon}></img>
            </div>
            <span>{text}</span>
          </div>
        </div>
      </div>
    )
  }

  WashButton.propTypes = {
    icon: PropTypes.node.isRequired,
    id: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    svgIcon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
  }

  const handleLogOut = () => {
    logOut()
  }

  const handleMouseEnter = (washButtonNameHover) => {
    setMouseHover(washButtonNameHover)
  }

  return (
    <div className="homescreen-container">
      <div className="homescreen-header">
        <AppNavbar setIsInfoOpen={setIsInfoOpen} isInfoOpen={isInfoOpen} />
      </div>
      <div className="homescreen-component">
        <div className="homescreen-component__body">
          <div className="homescreen-component__body__welcome">
            <p>Glad to see you, {user.firstName}</p>
          </div>
          <div className="homescreen-component__body__description">
            <p>
              Please select from the following options whether you are picking
              up or dropping off today.
            </p>
          </div>
          <div className="homescreen-component__body__buttongroup">
            <Link
              to="/home-options"
              className="homescreen-component__body__buttongroup__link"
            >
              <div
                onMouseEnter={() => handleMouseEnter("dropoff")}
                onMouseLeave={() => setMouseHover("")}
              >
                <WashButton
                  text="Drop Off"
                  icon={mouseHover === "dropoff" ? dropOff : dropOffDark}
                  svgIcon={mouseHover === "dropoff" ? starLight : starDark}
                  key={0}
                  id={0}
                  isSelected={mouseHover === "dropoff"}
                />
              </div>
            </Link>
            <Link
              to="/pick-up"
              className="homescreen-component__body__buttongroup__link"
            >
              <div
                onMouseEnter={() => handleMouseEnter("pickup")}
                onMouseLeave={() => setMouseHover("")}
              >
                <WashButton
                  text="Pickup"
                  icon={mouseHover === "pickup" ? pickUpLight : pickUpDark}
                  svgIcon={
                    mouseHover === "pickup" ? identityLight : identityDark
                  }
                  key={1}
                  id={1}
                  isSelected={mouseHover === "pickup"}
                />
              </div>
            </Link>
          </div>
          <div className="homescreen-component__body__message">
            <p>Whoops... Forgot my washing</p>
          </div>
          <button
            type="button"
            onClick={handleLogOut}
            disabled={isLoadingRequest}
            className="homescreen-component__body__logout"
          >
            Logout
          </button>
        </div>
      </div>
      <div className="homescreen-component__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
      {isInfoOpen && <HomeInfo setIsInfoOpen={setIsInfoOpen} />}
    </div>
  )
}

export default Homescreen
