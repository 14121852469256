import React from "react"

import HomescreenComponent from "../components/Application/HomescreenComponent"
import ProtectedRouteLayout from "../components/Layout/ProtectedRouteLayout"

const Home = () => {
  return (
    <ProtectedRouteLayout>
      <HomescreenComponent />
    </ProtectedRouteLayout>
  )
}

export default Home
