import React from "react"

import propTypes from "prop-types"

import close from "../../assets/images/close-menu.svg"
import { AuthenticationContext } from "../../context/"
import "./HomeInfo.scss"
import SocialMedia from "./SocialMedia"

const HomeInfo = ({ setIsInfoOpen }) => {
  const infoItems = [
    {
      description:
        "Our entry-level subscription is designed for a single user doing 1 load of laundry per week.",
      title: "Single: $100pm",
    },
    {
      description:
        "Designed for a couple or two people doing 1-2 loads of laundry per week. Data indicates two-person households wash on average four loads per week.",
      title: "Couple: $200pm",
    },
    {
      description:
        "Designed with families in mind this subscription caters to five people doing 1-2 loads per week. Research indicates the average Australian family of five will do nine loads of laundry per week.",
      title: "Family: $400pm",
    },
  ]

  const { checkIsAuthenticated } = React.useContext(AuthenticationContext)

  React.useEffect(() => {
    checkIsAuthenticated()
  }, [])

  return (
    <div className="home-info-container">
      <div className="home-info">
        <div className="home-info__close">
          <img src={close} onClick={() => setIsInfoOpen(false)} />
        </div>

        <div className="home-info__matter">
          <p className="home-info__matter__heading">Pay as you go</p>
          <div className="home-info__matter__description">
            <p>
              A more traditional payment system for those who are new to
              Washroom or those needing a one-off wash. Laundry is priced by
              weight at $5 per Kg <br />
              <br></br> Subscriptions- All subscription holders receive %20 off
              dry cleaning
            </p>
          </div>
        </div>

        <div className="home-info__options">
          {infoItems.map((item, index) => {
            return (
              <div key={index} className="home-info__option">
                <p className="home-info__option__title">{item.title}</p>
                <div className="home-info__option__description">
                  <p>{item.description}</p>
                </div>
              </div>
            )
          })}
        </div>
        <SocialMedia />
      </div>
    </div>
  )
}

HomeInfo.propTypes = {
  setIsInfoOpen: propTypes.func.isRequired,
}

export default HomeInfo
