import React from "react"

import PropTypes from "prop-types"

import info from "../../assets/images/Icons/info.svg"
import settings from "../../assets/images/Icons/settings.svg"
import logo from "../../assets/images/Logo.svg"
import Link from "../../utils/link"

import "./AppNavbar.scss"

const AppNavbar = (props) => {
  const handleChange = () => {
    props.setIsInfoOpen(true)
  }

  return (
    <div className="app-navbar">
      <div className="app-navbar__icons">
        <div>
          <img
            src={info}
            onClick={handleChange}
            className="app-navbar__icons__icon"
          />
        </div>
        <Link to="/settings" className="app-navbar__icons__icon">
          <img src={settings} />
        </Link>
      </div>
      <Link to="/home" className="app-navbar__logo">
        <div>
          <img src={logo} />
        </div>
      </Link>
    </div>
  )
}

AppNavbar.propTypes = {
  setIsInfoOpen: PropTypes.func,
}

export default AppNavbar
