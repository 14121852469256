import React, { useContext, useEffect, useState } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import { AuthenticationContext } from "../../context"

const ProtectedRouteLayout = ({ children }) => {
  const { checkIsAuthenticated, logOut } = useContext(AuthenticationContext)
  const [isMounted, setTsMounted] = useState(false)

  useEffect(() => {
    if (!checkIsAuthenticated()) {
      const location = JSON.parse(localStorage.getItem("loc"))
      if (location) navigate(`/${location.slug}/get-started`)
      else navigate("/")
      logOut()
      return null
    }

    if (!JSON.parse(localStorage.getItem("location"))) {
      navigate("/location-access")
      return
    }

    setTsMounted(true)
  }, [])

  if (!isMounted) {
    return <div></div>
  }

  return <div>{children}</div>
}

ProtectedRouteLayout.propTypes = {
  children: PropTypes.any.isRequired,
}
export default ProtectedRouteLayout
